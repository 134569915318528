import { message } from "antd";
import moment from 'moment';
import { getAllFilteredCustomers, getAllFilteredVehicles } from "./actions";

const PDFJS = require("pdfjs-dist/webpack");

const invalidStringValue = "No proper value (invalid, inspect)";

export const HardReloadFrontend = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
	  registrations.forEach((registration) => {
		  registration.unregister();
	  });
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    window.location.reload();
  }, 900);
} 

export const formatCustomerSearchDisplayLabelValue = (customer) => {
  const isCompany = customer?.is_company;
  const companyNamePrivateCustomer = customer?.company_name ? `(${customer?.company_name})` : "";
  const customerNameCompanyCustomer = customer?.first_name || customer?.last_name ? `(${customer?.first_name || ''} ${customer?.last_name || ''})` : "";
  const labelNameValue = isCompany ? `Company: ${customer?.company_name} ${customerNameCompanyCustomer}` : `${customer?.first_name} ${customer?.last_name} ${companyNamePrivateCustomer}`;
  return labelNameValue;
} 

export const formatCustomerDisplayValue = (customer) => {
  const companyNamePrivateCustomer = customer?.company_name ? `(${customer?.company_name})` : "";
  const customerNameCompanyCustomer = customer?.first_name || customer?.last_name ? `(${customer?.first_name || ''} ${customer?.last_name || ''})` : "";
  if(customer?.is_company) {
    return `${customer?.company_name} ${customerNameCompanyCustomer}`;
  }
  return `${customer?.first_name} ${customer?.last_name} ${companyNamePrivateCustomer}`;
} 

export const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

//param: file -> blob
//return: images -> an array of images encoded in base64 
export const convertPdfBlobToImages = async (file) => {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);

    const scale = 1.2;
    const rotation = 0;
    const dontFlip = false
    const viewport = page.getViewport(scale, rotation, dontFlip);
    const context = canvas.getContext('2d');

    canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
    canvas.width = viewport.width || viewport.viewBox[2];  /* viewport.width is also NaN */
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    const canvasDataUrl = canvas.toDataURL("image/png");
    images.push(canvasDataUrl);
  }
  canvas.remove();
  return images;
}

const searchInChildren = (children, value, currentArray) => {
  if (children.children !== undefined) {
    currentArray.push(children.value);
    currentArray = searchInChildren(children.children, value, currentArray);
    return currentArray;
  }
  for (const childrenValue of children) {
    if (childrenValue.value === value) {
      currentArray.push(value);
      return currentArray;
    }
  }
  return currentArray;
}

export const GetTreeArrayValuesCascader = (options, value) => {
  for (const item of options) {
    if (item.value === value) {
      return [value];
    }
    if (item.children !== undefined) {
      let result = searchInChildren(item.children, value, [item.value]);
      if (result.at(-1) === value) {
        return result;
      }
    }
  }

  return null;
}

export const IsString = (x) => {
  return Object.prototype.toString.call(x) === "[object String]"
}

export const DollarFormatter = (value) => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const MobileJobDistanceFormatter = (value) => {
  if (value === undefined || value === null) return "";
  return `${((value / 1609) || -1).toFixed(2)} miles`;
}

export const MobileJobTimeFormatter = (value) => {
  if (value === undefined || value === null) return "";
  return `${moment.duration(value, 'seconds').humanize()} (${value} seconds)`
}

export const DollarParser = (value) => {
  return value.replace(/\$\s?|(,*)/g, '')
}

export const replaceCharacter = (str, index, replaceChar="") => {
  return str.substring(0, index) + replaceChar + str.substring(index + 1);
}

export const compareStringsCaseInsensitiveTrimmed = (s1, s2) => {
  s1 = s1.toLowerCase().trim();
  s2 = s2.toLowerCase().trim();
  return s1 === s2;
} 

export const GetVehicleSelectionComponentForJobs = (vehicles) => { 
  return {
    title: 'Vehicle',
    inputType: "ddl",
    required: false,
    editable: true,
    clearable: true,
    dataIndex: 'intake_form_vehicle_id',
    width: '150px',
    ddlOptions: vehicles.filter(x => x.id !== undefined).map((x) => {
      let vehicleDDLOption = {
        key: x.id,
        value: x.id.toString(),
        label: `${x.year} ${x.make} ${x.model}`
      }
      return vehicleDDLOption;
    }),
  }
}

export const GetTechnicianEmployeeSelectionComponentForJobs = (technicianEmployees) => { 
  return {
    title: 'Technicians',
    inputType: "ddl",
    required: false,
    editable: true,
    clearable: true,
    multiple: true,
    dataIndex: 'technician_user_ids',
    width: '150px',
    size: "small",
    ddlOptions: technicianEmployees.filter(x => x.id !== undefined).map((x) => {
      let technicianEmployee = {
        key: x.id,
        value: x.id,
        label: `${x.first_name} ${x.last_name}`
      }
      return technicianEmployee;
    }),
  }
}

export const nicelyFormatSnakeCaseString = (string) => {
  const pascalCaseString = snakeCaseToPascal(string);
  if (pascalCaseString === invalidStringValue) {
    return invalidStringValue;
  }
  return nicelyFormatPascalCaseString(pascalCaseString);
}

export const snakeCaseToPascal = (string) => {
  if (string === null || string === undefined) return invalidStringValue;
  return string.split("/")
    .map(snake => snake.split("_")
      .map(substr => substr.charAt(0)
        .toUpperCase() +
        substr.slice(1))
      .join(""))
    .join("/");
};

export const nicelyFormatPascalCaseString = (camelCaseString) => {
  if (camelCaseString === null || camelCaseString === undefined) return invalidStringValue;
  return camelCaseString
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function(str){ return str.toUpperCase(); })
}

function isNumeric(str) {
  if (typeof str != "string") return false // only strings
  return !isNaN(str) &&
    !isNaN(parseFloat(str)) // ensure strings of whitespace fail
}

export const roundNumber = (number, decimals=12) => {
  var newNumber = new Number(number+'').toFixed(parseInt(decimals));
  return parseFloat(newNumber); 
}

export async function fetchVehicles(searchTerm) {
  return getAllFilteredVehicles(searchTerm)
    .then((response) =>
      //TODO: Fix this stringify mess
      response.data.map((vehicle) => ({
        label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        value: JSON.stringify({
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model
        }),
        key: vehicle.id,
      })))
    .catch((err) => {
      message.error(JSON.stringify(err.response.data.detail));
    });
}

export async function fetchCustomers(searchTerm, customerId) {
  return getAllFilteredCustomers(searchTerm, customerId)
    .then((response) => {
      //TODO: Fix this stringify mess
      return response.data.map((customer) => {
        const isCompany = customer.is_company;
        const labelNameValue = isCompany ? `Company: ${customer.company_name}` : `${customer.first_name} ${customer.last_name}`;
        return {
          label: `${labelNameValue}, Phone #: ${customer.telephone}`,
          value: JSON.stringify({
            ...customer,
            net_term: customer.default_net_term,
            is_vip: customer.default_is_vip
          }),
          key: customer.id,
        }
      })
    })
    .catch((err) => {
      message.error(JSON.stringify(err.response.data.detail));
    });
}

export const singleIntakeFormMap = (item) => {
  if (item.appointment_time !== null && item.appointment_time !== undefined) {
    item.appointment_time = moment.utc(item.appointment_time).local()
  }

  if (item.customer_check_out_estimate !== null && item.customer_check_out_estimate !== undefined) {
    item.customer_check_out_estimate = moment.utc(item.customer_check_out_estimate).local()
  }

  const setKeyFieldToIdFieldValue = (item) => {
    item.key = item.id;
    return item;
  };

  if (item.customer !== null) {
    item.customer_info_json = {
      label: `${item.customer.first_name} ${item.customer.last_name}, Phone #: ${item.customer.telephone}`,
      value: JSON.stringify({
        ...item.customer,
        default_net_term: item.customer.default_net_term,
        default_is_vip: item.customer.default_is_vip
      }),
      key: item.customer.id,
    }
  }
  
  item.glass_replacement_jobs = (item.glass_replacement_jobs || []).map(setKeyFieldToIdFieldValue);
  item.chip_repair_jobs = (item.chip_repair_jobs || []).map(setKeyFieldToIdFieldValue);
  item.body_and_paint_jobs = (item.body_and_paint_jobs || []).map(setKeyFieldToIdFieldValue);
  item.vehicle_detail_jobs = (item.vehicle_detail_jobs || []).map(setKeyFieldToIdFieldValue);
  item.tint_only_jobs = (item.tint_only_jobs || []).map(setKeyFieldToIdFieldValue);
  item.key = item.id
  return item;
}

export const objectsEqual = (o1, o2) => {
  return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p]);
}

export const getCSRStringFromIntakeForm = (record) => {
  let csr = [record.csr_user, record.csr_user_2];
  let vals = csr.filter(csrRecord => csrRecord !== null && csrRecord !== undefined);
  let nameStrings = vals.map(csrRecord => {
    return csrRecord.first_name;
  }).join(", ");

  return (
    <p>{nameStrings}</p>
  );
}

export const autoglassCrmFeaturesDisplayNameMapping = (shortName) => {
  const mapping = {
    "ldws": "Lane Departure Warning System", 
    "rs": "Rain Sensor",
    "hud": "Heads Up Display",
    "hwp": "Heated Wiper Park",
    "cs": "Condensation Sensor",
    "ecm": "Electrochromic Mirror",
    "fca": "Forward Collision Alert",
    "sesldws": "Subaru Eye Sight ldws",
    "mldws": "Mitsubishi ldws",
    "tldws": "Tesla ldws",
    "caltype": "Calibration Type",
  }
  try {
    return mapping[shortName];
  } catch (error) {
    return -1;
  }
}
