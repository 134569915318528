export const AUTHORIZATION_KEY = "atlas_intake_form";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const displayDateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const displayTimeOnlyFormat = 'hh:mm A';
export const chatDisplayDateTimeFormat = 'MM/DD hh:mm A';
export const backendDisplayDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const phoneNumberMaskFormat = "(111) 111-1111"
export const phoneNumberMaskFormatNines = "(999) 999-9999"
export const callRailLogPhoneNumberMaskFormat = "111-111-1111"

export const INVENTORY_GLASS_SOURCES = [
  {
    "value": "Atlas Inventory",
    "label": "Atlas Inventory"
  },
  {
    "value": "Affordable",
    "label": "Affordable"
  },
  {
    "value": "Mygrant",
    "label": "Mygrant"
  },
  {
    "value": "Vitro",
    "label": "Vitro"
  },
  {
    "value": "American",
    "label": "American"
  },
  {
    "value": "Pilkington",
    "label": "Pilkington"
  },
  {
    "value": "PGW",
    "label": "PGW"
  },
  {
    "value": "UniGlass",
    "label": "UniGlass"
  },
  {
    "value": "Labor Only",
    "label": "Labor Only"
  },
  {
    "value": "Other",
    "label": "Other"
  },
];

export const GLASS_TYPE_VALUES = [
  {
    "value": "FrontDriverWindow",
    "label": "Front - Driver Window"
  },
  {
    "value": "FrontPassengerWindow",
    "label": "Front - Passenger Window"
  },
  {
    "value": "RearDriverWindow",
    "label": "Rear - Driver Window"
  },
  {
    "value": "RearPassengerWindow",
    "label": "Rear - Passenger Window"
  },
  {
    "value": "Windshield",
    "label": "Windshield"
  },
  {
    "value": "BackGlassBackWindshield",
    "label": "Back Glass/Back Windshield"
  },
  {
    "value": "QuarterGlass",
    "label": "Quarter Glass"
  },
  {
    "value": "VentGlass",
    "label": "Vent Glass"
  },
  {
    "value": "Sunroof",
    "label": "Sunroof"
  },
  {
    "value": "Other",
    "label": "Other (specify)"
  },
]

export const GLASS_TYPE_CASCADER_VALUES = [
  {
    "value": "Windshield",
    "label": "Windshield",
  },
  {
    "value": "DriverLeft",
    "label": "Driver (Left)",
    "children": [
      {
        "label": "Front",
        "value": "FrontDriverWindow"
      },
      {
        "label": "Rear",
        "value": "RearDriverWindow"
      },
    ]
  },
  {
    "value": "PassengerRight",
    "label": "Passenger (Right)",
    "children": [
      {
        "label": "Front",
        "value": "FrontPassengerWindow"
      },
      {
        "label": "Rear",
        "value": "RearPassengerWindow"
      },
    ]
  },
  {
    "value": "BackGlassBackWindshield",
    "label": "Back Glass"
  },
  {
    "value": "QuarterGlass",
    "label": "Quarter",
    "children": [
      {
        "label": "Front",
        "value": "FrontQuarterGlass"
      },
      {
        "label": "Rear",
        "value": "RearQuarterGlass"
      },
    ]
  },
  {
    "value": "VentGlass",
    "label": "Vent",
    "children": [
      {
        "label": "Front",
        "value": "FrontVentGlass"
      },
      {
        "label": "Rear",
        "value": "RearVentGlass"
      },
    ]
  },
  {
    "value": "Sunroof",
    "label": "Sunroof"
  },
  {
    "value": "Other",
    "label": "Other (specify)"
  },
]

export const STATUS_VALUES = [
  {
    "value": "Quote",
    "label": "Quote"
  },
  {
    "value": "Scheduled",
    "label": "Scheduled"
  },
  {
    "value": "GlassOrdered",
    "label": "Glass Ordered"
  },
  {
    "value": "GlassReadyForPickup",
    "label": "Glass Ready For Pickup"
  },
  {
    "value": "GlassArrived",
    "label": "Glass Arrived"
  },
  {
    "value": "InstallationInProgress",
    "label": "Installation In Progress"
  },
  {
    "value": "InstallationComplete",
    "label": "Installation Complete"
  },
  {
    "value": "CustomerPrepaid",
    "label": "Customer Prepaid"
  },
  {
    "value": "PaymentComplete",
    "label": "Payment Complete"
  },
  {
    "value": "RejectedQuote",
    "label": "Rejected Quote"
  },
  {
    "value": "CustomerCancelled",
    "label": "Customer Cancelled"
  },
  {
    "value": "CustomerNoShow",
    "label": "Customer No Show"
  },
  {
    "value": "DoNotRelease",
    "label": "Do Not Release"
  }
]

export const STATUS_FILTERING_VALUES = [
  {
    "value": "NoJobs",
    "label": "No Jobs"
  },
  ...STATUS_VALUES
]

export const NET_TERM_VALUES = [
  {
    "value": "OnReceipt",
    "label": "On Receipt"
  },
  {
    "value": "_7",
    "label": "7 days"
  },
  {
    "value": "_15",
    "label": "15 days"
  },
  {
    "value": "_30",
    "label": "30 days"
  },
  {
    "value": "_60",
    "label": "60 days"
  },
  {
    "value": "_90",
    "label": "90 days"
  }
]

export const SQUARE_INVOICE_STATUS_VALUES = [
  {
    "value": "Canceled",
    "label": "Canceled"
  },
  {
    "value": "Deleted",
    "label": "Deleted"
  },
  {
    "value": "Draft",
    "label": "Draft"
  },
  {
    "value": "Published",
    "label": "Published"
  },
  {
    "value": "Paid",
    "label": "Paid"
  },
  {
    "value": "ScheduledPaymentFailed",
    "label": "Scheduled Payment Failed"
  },
  {
    "value": "Refunded",
    "label": "Refunded"
  }
]

export const PAYMENT_STATUS_VALUES = [
  {
    "value": "PaymentRequired",
    "label": "Payment Required"
  },
  {
    "value": "DepositRequest",
    "label": "Deposit Requested"
  },
  {
    "value": "DepositReceived",
    "label": "Deposit Received"
  },
  {
    "value": "PaymentReceived",
    "label": "Payment Received"
  }
]

export const CUSTOMER_STATUS_VALUES = [
  {
    "value": "NotCheckedIn",
    "label": "Not Checked In"
  },
  {
    "value": "CheckedInAndWaiting",
    "label": "Checked In & Waiting"
  },
  {
    "value": "CheckedInAndKeysDroppedOff",
    "label": "Checked In & Not Waiting"
  },
  {
    "value": "ReadyToCheckOut",
    "label": "Ready To Check Out"
  },
  {
    "value": "CheckedOut",
    "label": "Checked Out"
  }
]

export const ADD_STATUS_VALUES = [
  {
    "value": "Quote",
    "label": "Quote"
  },
  {
    "value": "Scheduled",
    "label": "Scheduled"
  }
]

export const CALLRAIL_STATUS_VALUES = [
  {
    "value": "Answered",
    "label": "Answered Call"
  },
  {
    "value": "OutboundCall",
    "label": "Outbound Call"
  },
  {
    "value": "FormReceived",
    "label": "Form Received"
  }
]

export const CUSTOMER_CHECK_IN_STATUS_VALUES = [
  {
    "value": "NotCheckedIn",
    "label": "Not Checked In"
  },
  {
    "value": "CheckedInAndWaiting",
    "label": "Checked In And Waiting",
    "customerFacingLabel": "I'll wait"
  },
  {
    "value": "CheckedInAndKeysDroppedOff",
    "label": "Checked In And Not Waiting",
    "customerFacingLabel": "Drop Off"
  },
  {
    "value": "ReadyToCheckOut",
    "label": "Ready To Check Out"
  },
  {
    "value": "CheckedOut",
    "label": "Checked Out"
  }
]

export const IGNORE_CUSTOMER_CALLRAIL_RECORDS_VALUES = [
  {
    "value": "GeneralBusinessCall",
    "label": "General Business Call"
  },
  {
    "value": "Price",
    "label": "Price"
  },
  {
    "value": "MissedOpportunity",
    "label": "Missed Opportunity"
  },
  {
    "value": "GlassAvailability",
    "label": "Glass Availability"
  },
  {
    "value": "JobAvailability",
    "label": "Job Availability"
  }
]

export const TINT_PRICING = [
  {"id": 1, "label": "tinting car - $250", "value": 250},
  {"id": 2, "label": "tinting truck - $300", "value": 300},
  {"id": 3, "label": "custom price", "value": null}
]

export const QRSCAN_JOB_CURRENT_STATUS_MAPPINGS = { 
  "1": "Sale",
  "2": "Transfer for Sale",
  "2.5": "Glass Ready for Pickup",
  "3": "Glass Ordered from Vendor",
  "4": "Glass in Transit from Vendor",
  "5": "Glass On Site from Vendor",
  "6": "Glass In Transit",
  "7": "Glass On Site",
  "8": "Vendor Return Needed",
  "9": "Vendor Return Complete",
  "10": "Job Started",
  "11": "Job Completed",
  "12": "Damaged",
  "13": "Restock",
  "14": "Reassign Part",
  "15": "Cancel Sale"
}

export const WEB_FORM_LOOKUP = {
  "your-name": "first_last", 
  "your-email": "email",
  "phone": "telephone",
  "recipient": "city",
  "zipcode": "zipcode",
  "date-501": "wanted_date",
  "Year": "year",
  "Make": "make",
  "Model": "model",
  "VIN": "vin",
  "our-message": "job_notes"
}