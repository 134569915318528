export const USER_API_PATH = "user";
export const ALL_CSR_USERS_API_PATH = `${USER_API_PATH}/csr-employees`;
export const ALL_TECHNICIAN_USERS_API_PATH = `${USER_API_PATH}/technician-employees`;
export const ALL_DELIVERY_DRIVERS_USERS_API_PATH = `${USER_API_PATH}/delivery-drivers`;
export const CURRENT_USER_INFO_API_PATH = `${USER_API_PATH}/me`;
export const GET_ALL_ACCOUNT_USERS_API_PATH = `${USER_API_PATH}/all`;
export const GET_SINGLE_USER_API_PATH = `${USER_API_PATH}?id={userId}`;
export const ADD_USERS_API_PATH = `${USER_API_PATH}/add-user-to-current-account`;
export const EDIT_USER_API_PATH = `${USER_API_PATH}/edit?id={userId}`;
export const DELETE_USER_API_PATH = `${USER_API_PATH}/enable-disable-user?id={userId}&disabled={disabled}`;
export const UPDATE_CURRENT_ACTIVE_SHOP_LOCATION_API_PATH = `${USER_API_PATH}/update-current-active-shop-location?id={userId}`

export const REGISTER_API_PATH = "register/new-account";

export const LOGIN_API_PATH = "login";
export const AUTH_USER_API_PATH = "auth_user";

export const INTAKE_FORMS_API_PATH = "intake-form";
export const GET_ALL_INTAKE_FORMS_API_PATH = `${INTAKE_FORMS_API_PATH}/all`;
export const GET_SINGLE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}?id={intakeFormId}`;
export const ADD_INTAKE_FORMS_API_PATH = `${INTAKE_FORMS_API_PATH}/add`;
export const EDIT_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/edit?id={intakeFormId}`;
export const CHECK_IN_MANAGEMENT_CHANGE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/check-in-management-change?id={intakeFormId}`;
export const JOB_NOTE_EDIT_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/job-note-edit?id={intakeFormId}`;
export const DELETE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}?id={intakeFormId}`;
export const QUICK_STATUS_CHANGE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/quick-status-change?id={intakeFormId}&target_status={targetStatusValue}`;
export const CUSTOMER_CHECK_IN_STATUS_QUICK_CHANGE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/check-in-quick-status-change?id={intakeFormId}&target_status={targetStatusValue}`;
export const TECHNICIAN_QUICK_CHANGE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/technician-quick-change?id={intakeFormId}&technician_user_id={technicianUserId}`;
export const APPOINTMENT_TIME_QUICK_CHANGE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/appointment-time-quick-change?id={intakeFormId}&appointment_time={appointmentTime}`;
export const CLONE_WARRANTY_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/warranty-clone`;
export const QUICK_QUOTE_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/quick-quote`;
export const SPLIT_JOBS_INTAKE_FORM_API_PATH = `${INTAKE_FORMS_API_PATH}/split-job`;

export const VEHICLES_API_PATH = "vehicle";
export const GET_ALL_VEHICLES_API_PATH = `${VEHICLES_API_PATH}/all`;
export const GET_ALL_FILTERED_VEHICLES_API_PATH = `${VEHICLES_API_PATH}/all-filtered?search_term={searchTerm}`;
export const ADD_VEHICLES_API_PATH = `${VEHICLES_API_PATH}/add`;
export const EDIT_VEHICLE_API_PATH = `${VEHICLES_API_PATH}/edit?id={vehicleId}`;
export const DELETE_VEHICLE_API_PATH = `${VEHICLES_API_PATH}?id={vehicleId}`;

export const SHOP_LOCATIONS_API_PATH = "shop-location";
export const GET_ALL_SHOP_LOCATIONS_API_PATH = `${SHOP_LOCATIONS_API_PATH}/all`;
export const GET_ALL_SHOP_LOCATIONS_DETAILED_API_PATH = `${SHOP_LOCATIONS_API_PATH}/all-detailed`;
export const GET_SINGLE_SHOP_LOCATION_API_PATH = `${SHOP_LOCATIONS_API_PATH}?id={shopLocationId}`;
export const ADD_SHOP_LOCATIONS_API_PATH = `${SHOP_LOCATIONS_API_PATH}/add`;
export const EDIT_SHOP_LOCATION_API_PATH = `${SHOP_LOCATIONS_API_PATH}/edit?id={shopLocationId}`;
export const DELETE_SHOP_LOCATION_API_PATH = `${SHOP_LOCATIONS_API_PATH}?id={shopLocationId}`;

export const GOOGLE_CALENDAR_API_PATH = "google-calendar";
export const GET_ALL_GOOGLE_CALENDARS_API_PATH = `${GOOGLE_CALENDAR_API_PATH}/all-calendars`;

export const SQUARE_INTEGRATION_API_PATH = "square-integration";
export const PUBLISH_INTAKE_FORM_SQUARE_INVOICE_API_PATH = `${SQUARE_INTEGRATION_API_PATH}/publish-invoice?intake_form_id={intakeFormId}`;

export const TINT_PRICINGS_API_PATH = "tint-pricing";
export const GET_ALL_TINT_PRICINGS_API_PATH = `${TINT_PRICINGS_API_PATH}/all`;
export const GET_SINGLE_TINT_PRICING_API_PATH = `${TINT_PRICINGS_API_PATH}?name={tintPricingId}`;
export const ADD_TINT_PRICINGS_API_PATH = `${TINT_PRICINGS_API_PATH}/add`;
export const EDIT_TINT_PRICING_API_PATH = `${TINT_PRICINGS_API_PATH}/edit?name={tintPricingId}`;
export const DELETE_TINT_PRICING_API_PATH = `${TINT_PRICINGS_API_PATH}?name={tintPricingId}`;

export const INSURANCE_CARRIERS_API_PATH = "insurance-carrier";
export const GET_ALL_INSURANCE_CARRIERS_API_PATH = `${INSURANCE_CARRIERS_API_PATH}/all`;
export const GET_SINGLE_INSURANCE_CARRIER_API_PATH = `${INSURANCE_CARRIERS_API_PATH}?id={insuranceCarrierId}`;
export const ADD_INSURANCE_CARRIERS_API_PATH = `${INSURANCE_CARRIERS_API_PATH}/add`;
export const EDIT_INSURANCE_CARRIER_API_PATH = `${INSURANCE_CARRIERS_API_PATH}/edit?id={insuranceCarrierId}`;
export const DELETE_INSURANCE_CARRIER_API_PATH = `${INSURANCE_CARRIERS_API_PATH}?id={insuranceCarrierId}`;

export const SETTINGS_API_PATH = "settings";
export const GET_SETTINGS_API_PATH = `${SETTINGS_API_PATH}`;
export const EDIT_SETTINGS_API_PATH = `${SETTINGS_API_PATH}/edit`;

export const CUSTOMERS_API_PATH = "customer";
export const GET_ALL_CUSTOMERS_API_PATH = `${CUSTOMERS_API_PATH}/all`;
export const GET_ALL_FILTERED_CUSTOMERS_API_PATH = `${CUSTOMERS_API_PATH}/all-filtered?search_term={searchTerm}&customer_id={customerId}`;
export const GET_SINGLE_CUSTOMER_API_PATH = `${CUSTOMERS_API_PATH}?id={customerId}`;
export const GET_CUSTOMER_BY_PHONE_NUMBER_API_PATH = `${CUSTOMERS_API_PATH}/search-phone-number?phone_number={phoneNumber}`;
export const ADD_CUSTOMERS_API_PATH = `${CUSTOMERS_API_PATH}/add`;
export const EDIT_CUSTOMER_API_PATH = `${CUSTOMERS_API_PATH}/edit?id={customerId}`;
export const DELETE_CUSTOMER_API_PATH = `${CUSTOMERS_API_PATH}?id={customerId}`;

export const FILE_VIEWER_API_PATH = "file-viewer";
export const GET_ALL_CUSTOMERS_FILES_API_PATH = `${FILE_VIEWER_API_PATH}/all`;

export const CALLRAIL_WEBHOOKS_API_PATH = "callrail";
export const GET_ALL_CALLRAIL_WEBHOOKS_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/all`;
export const GET_ALL_CALLRAIL_WEBHOOK_MODE_COUNTS_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/count-all-filter-modes`;
export const GET_SINGLE_CALLRAIL_WEBHOOK_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}?id={callRailWebhookId}`;
export const GET_ASSIGNED_AGENTS_CALLRAIL_WEBHOOK_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/assigned-agents?id={callRailWebhookId}`;
export const ADD_CALLRAIL_WEBHOOKS_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/add`;
export const EDIT_CALLRAIL_WEBHOOK_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/edit?id={callRailWebhookId}`;
export const ASSIGN_AGENTS_TO_CALLRAIL_PHONE_NUMBER_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/assign-agents?id={callRailWebhookId}`;
export const GET_COUNT_UNASSIGNED_NEEDS_ATTENTION_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/get-unassigned-needs-attention`;
export const GET_COUNT_NEEDS_ATTENTION_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/get-needs-attention`;
export const IGNORE_CALLRAIL_WEBHOOK_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}/ignore?id={callRailWebhookId}`;
export const DELETE_CALLRAIL_WEBHOOK_API_PATH = `${CALLRAIL_WEBHOOKS_API_PATH}?id={callRailWebhookId}`;

export const CALLRAIL_USERS_API_PATH = "callrail-user";
export const GET_ALL_CALLRAIL_USERS_API_PATH = `${CALLRAIL_USERS_API_PATH}/all`;
export const GET_SINGLE_CALLRAIL_USER_API_PATH = `${CALLRAIL_USERS_API_PATH}?id={callRailUserId}`;
export const ADD_CALLRAIL_USERS_API_PATH = `${CALLRAIL_USERS_API_PATH}/add`;
export const EDIT_CALLRAIL_USER_API_PATH = `${CALLRAIL_USERS_API_PATH}/edit?id={callRailUserId}`;
export const DELETE_CALLRAIL_USER_API_PATH = `${CALLRAIL_USERS_API_PATH}?id={callRailUserId}`;

export const CALLRAIL_TEXT_RECORDS_API_PATH = "callrail-text";
export const GET_ALL_CALLRAIL_TEXT_CONVERSATIONS_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/conversations`;
export const GET_ALL_CALLRAIL_TEXTS_FOR_CONVERSATION_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/conversation?conversation_id={conversationId}`;
export const GET_ALL_CALLRAIL_TEXTS_FOR_PHONE_NR_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/conversation?phone_number_search={phoneNumber}`;
export const GET_ALL_CALLRAIL_TEXT_RECORDS_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/all`;
export const GET_SINGLE_CALLRAIL_TEXT_RECORD_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}?id={callRailTextRecordId}`;
export const ADD_CALLRAIL_TEXT_RECORDS_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/add`;
export const EDIT_CALLRAIL_TEXT_RECORD_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/edit?id={callRailTextRecordId}`;
export const SEND_TEXT_MESSAGE_CALLRAIL_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/send-message?conversation_id={conversationId}`;
export const SEND_TEXT_MESSAGE_CALLRAIL_PHONE_NUMBER_ONLY_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/send-message-phone-nr?phone_nr={phoneNr}`;
export const ACKNOWLEDGE_ALL_MESSAGES_FOR_CONVERSATION_CALLRAIL_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/review-entire-conversation?conversation_id={conversationId}`;
export const UNDO_ACKNOWLEDGE_ALL_MESSAGES_FOR_CONVERSATION_CALLRAIL_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}/undo-review-entire-conversation?conversation_id={conversationId}`;
export const DELETE_CALLRAIL_TEXT_RECORD_API_PATH = `${CALLRAIL_TEXT_RECORDS_API_PATH}?id={callRailTextRecordId}`;

export const CALLRAIL_CONVERSATIONS_API_PATH = "callrail-conversation";
export const GET_ALL_CALLRAIL_CONVERSATIONS_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/all`;
export const GET_SINGLE_CALLRAIL_CONVERSATION_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}?id={callRailConversationId}`;
export const GET_CALLRAIL_CONVERSATION_FOR_PHONE_NR_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/phone-number-search?phone_number_search={phoneNumber}`;
export const GET_VIN_IN_CALLRAIL_CONVERSATION_FOR_PHONE_NR_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/search-vin-in-conversation?phone_number={phoneNumber}`;
export const ADD_CALLRAIL_CONVERSATIONS_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/add`;
export const EDIT_CALLRAIL_CONVERSATION_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/edit?id={callRailConversationId}`;
export const EDIT_TAGS_CALLRAIL_CONVERSATION_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/edit-tags?id={callRailConversationId}`;
export const DELETE_CALLRAIL_CONVERSATION_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}?id={callRailConversationId}`;
export const GET_FIRST_LAST_NAME_CONVERSATION_API_PATH = `${CALLRAIL_CONVERSATIONS_API_PATH}/customer-name-from-conversation-manual-match?conversation_id={callRailConversationId}`;

export const PORTAL_HEALTH_API_PATH = "health";
export const BACKEND_LOGS_API_PATH = `${PORTAL_HEALTH_API_PATH}/backend-logs`;
export const SPECIFIC_BACKEND_LOG_API_PATH = `${PORTAL_HEALTH_API_PATH}/get-backend-log?log_type={logType}`;

export const CHAT_QUICK_RESPONSES_API_PATH = "chat-quick-response";
export const GET_ALL_CHAT_QUICK_RESPONSES_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}/all`;
export const GET_SINGLE_CHAT_QUICK_RESPONSE_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}?id={chatQuickResponseId}`;
export const ADD_CHAT_QUICK_RESPONSES_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}/add`;
export const LOG_USAGE_CHAT_QUICK_RESPONSE_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}/log-chat-quick-response-usage?id={chatQuickResponseId}`;
export const EDIT_CHAT_QUICK_RESPONSE_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}/edit?id={chatQuickResponseId}`;
export const DELETE_CHAT_QUICK_RESPONSE_API_PATH = `${CHAT_QUICK_RESPONSES_API_PATH}?id={chatQuickResponseId}`;

export const CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH = "chat-quick-response-category";
export const GET_ALL_CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH = `${CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH}/all`;
export const GET_SINGLE_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH = `${CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH}?id={chatQuickResponseCategoryId}`;
export const ADD_CHAT_QUICK_RESPONSE_CATEGORYS_CATEGORIES_API_PATH = `${CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH}/add`;
export const EDIT_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH = `${CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH}/edit?id={chatQuickResponseCategoryId}`;
export const DELETE_CHAT_QUICK_RESPONSE_CATEGORY_API_PATH = `${CHAT_QUICK_RESPONSES_CATEGORIES_API_PATH}?id={chatQuickResponseCategoryId}`;

export const PHONE_NUMBER_HISTORY_API_PATH = "phone-number-history";
export const GET_ALL_CALLRAIL_RECORDS_FOR_PHONE_NUMBER_API_PATH = `${PHONE_NUMBER_HISTORY_API_PATH}/callrail-history?phone_number={phoneNumber}`;

export const GLASS_ABBREVIATIONS_API_PATH = "glass-type-abbreviation";
export const GET_ALL_GLASS_ABBREVIATIONS_API_PATH = `${GLASS_ABBREVIATIONS_API_PATH}/all`;
export const GET_SINGLE_GLASS_ABBREVIATION_API_PATH = `${GLASS_ABBREVIATIONS_API_PATH}?id={glassAbbreviationId}`;
export const ADD_GLASS_ABBREVIATIONS_API_PATH = `${GLASS_ABBREVIATIONS_API_PATH}/add`;
export const EDIT_GLASS_ABBREVIATION_API_PATH = `${GLASS_ABBREVIATIONS_API_PATH}/edit?id={glassAbbreviationId}`;
export const DELETE_GLASS_ABBREVIATION_API_PATH = `${GLASS_ABBREVIATIONS_API_PATH}?id={glassAbbreviationId}`;

export const TAGS_API_PATH = "tag";
export const GET_ALL_TAGS_API_PATH = `${TAGS_API_PATH}/all`;
export const GET_ALL_TAGS_REUSABLE_ONLY_API_PATH = `${TAGS_API_PATH}/all?is_reusable_only=true`;
export const GET_ALL_INTAKE_FORMS_TAGS_API_PATH = `${TAGS_API_PATH}/all?type=IntakeForm`;
export const GET_ALL_CONVERSATION_TAGS_API_PATH = `${TAGS_API_PATH}/all?type=Conversation`;
export const GET_SINGLE_TAG_API_PATH = `${TAGS_API_PATH}?id={tagId}`;
export const ADD_TAGS_API_PATH = `${TAGS_API_PATH}/add`;
export const EDIT_TAG_API_PATH = `${TAGS_API_PATH}/edit?id={tagId}`;
export const DELETE_TAG_API_PATH = `${TAGS_API_PATH}?id={tagId}`;

export const FILES_API_PATH = "file";
export const GET_ALL_FILES_API_PATH = `${FILES_API_PATH}/all`;
export const GET_SINGLE_FILE_API_PATH = `${FILES_API_PATH}?id={fileId}`;
export const ADD_FILES_API_PATH = `${FILES_API_PATH}/add`;
export const EDIT_FILE_API_PATH = `${FILES_API_PATH}/edit?id={fileId}`;
export const DELETE_FILE_API_PATH = `${FILES_API_PATH}?id={fileId}`;

export const AUTOGLASS_CRM_INTEGRATION_API_PATH = "autoglass-crm-integration";
export const GET_ALL_PARTS_FOR_VIN_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/get-part-nrs?vin={vin}&glass_type={glassType}`;
export const NAGS_PART_SEARCH_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/nags-part-nr-search?nags_part_nr={nagsPartNr}&is_automatic_request={isAutomaticRequest}`;
export const NAGS_PART_INTAKE_FORM_RESULTS_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/nags-part-nr-results?intake_form_id={intakeFormId}`;
export const EDIT_NAGS_PART_SEARCH_RESULT_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/edit-nags-part-nr-result?nags_part_nr={nagsPartNr}&new_hours={editedHours}`;
export const GET_ALL_MAKES_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/get-all-makes`;
export const GET_ALL_MODELS_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/get-all-models?make={make}&year={year}`;
export const GET_ALL_BODY_TYPES_API_PATH = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/get-all-body-types?make={make}&year={year}&model={model}`;
export const GET_ALL_PARTS_FOR_VEHICLE_INFO = `${AUTOGLASS_CRM_INTEGRATION_API_PATH}/vin-decode-vehicle-info`;

export const QRSCAN_INTEGRATION_API_PATH = "qrscan-integration";
export const GET_PART_INFO_API_PATH = `${QRSCAN_INTEGRATION_API_PATH}/get-part-info?part_number={partNumber}`;
export const CANCEL_SALE_API_PATH = `${QRSCAN_INTEGRATION_API_PATH}/cancel-sale?glass_replacement_job_id={glassReplacementJobId}`;

export const NHTSA_INTEGRATION_API_PATH = "nhtsa-integration";
export const GET_CAR_INFO_VIN_LOOKUP_API_PATH = `${NHTSA_INTEGRATION_API_PATH}/get-vehicle-info?vin={vin}`;

export const SQUARE_INVOICES_API_PATH = "square-invoice";
export const GET_ALL_SQUARE_INVOICES_API_PATH = `${SQUARE_INVOICES_API_PATH}/all`;
export const GET_SINGLE_SQUARE_INVOICE_API_PATH = `${SQUARE_INVOICES_API_PATH}?id={squareInvoiceId}`;
export const ADD_SQUARE_INVOICES_API_PATH = `${SQUARE_INVOICES_API_PATH}/add`;
export const EDIT_SQUARE_INVOICE_API_PATH = `${SQUARE_INVOICES_API_PATH}/edit?id={squareInvoiceId}`;
export const DELETE_SQUARE_INVOICE_API_PATH = `${SQUARE_INVOICES_API_PATH}?id={squareInvoiceId}`;

export const GOOGLE_DISTANCE_MATRIX_INTEGRATION_API_PATH = "google-maps-integration";
export const GET_MOBILE_LOCATION_DISTANCES_API_PATH = `${GOOGLE_DISTANCE_MATRIX_INTEGRATION_API_PATH}/get-mobile-locations?customer_location={address}`;

export const XYG_INTEGRATION_API_PATH = "xyg-integration";
export const GET_XYG_PARTS_FOR_NAGS_API_PATH = `${XYG_INTEGRATION_API_PATH}/get-part-by-nags?nags={nags}`;
export const GET_XYG_PART_FOR_NAGS_NR_API_PATH = `${XYG_INTEGRATION_API_PATH}/get-record-for-nags-part-nr?nags_part_nr={nags}`;

export const JOB_REVIEWS_API_PATH = "part-help-request";
export const GET_ALL_JOB_REVIEWS_API_PATH = `${JOB_REVIEWS_API_PATH}/all`;
export const GET_SINGLE_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}?id={jobReviewId}`;
export const ADD_JOB_REVIEWS_API_PATH = `${JOB_REVIEWS_API_PATH}/add`;
export const EDIT_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}/edit?id={jobReviewId}`;
export const INTERNAL_USER_PROVIDE_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}/internal-user-review?id={jobReviewId}`;
export const DELETE_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}?id={jobReviewId}`;
export const SELF_ASSIGN_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}/self-assign-job-review?id={jobReviewId}`;
export const GET_UNACKNOWLEDGED_JOB_REVIEWS_API_PATH = `${JOB_REVIEWS_API_PATH}/get-unacknowledged-job-reviews?intake_form_id={intakeFormId}`;
export const SET_ACKNOWLEDGED_FOR_JOB_REVIEW_API_PATH = `${JOB_REVIEWS_API_PATH}/set-job-review-as-acknowledged?id={jobReviewId}`;
export const GET_COUNT_UNASSIGNED_PENDING_INTERNAL_JOB_REVIEWS_API_PATH = `${JOB_REVIEWS_API_PATH}/count-unassigned-pending-internal`;

export const METABASE_INTEGRATION_API_PATH = "metabase-integration";
export const DYNAMIC_METABASE_DASHBOARD_API_PATH = `${METABASE_INTEGRATION_API_PATH}/dynamic-embed-by-dashboard-id?dashboard_id={dashboardId}`;

export const ACCOUNT_ADMIN_ACTIONS_API_PATH = "account-admin-actions";
export const RESET_ATTENTION_NEEDED_API_PATH = `${ACCOUNT_ADMIN_ACTIONS_API_PATH}/reset-needs-attention-flags`;

export const SQUARE_PAYMENTS_API_PATH = "square-payment";
export const GET_ALL_SQUARE_PAYMENTS_API_PATH = `${SQUARE_PAYMENTS_API_PATH}/all`;
export const GET_SINGLE_SQUARE_PAYMENT_API_PATH = `${SQUARE_PAYMENTS_API_PATH}?id={squarePaymentId}`;
export const ADD_SQUARE_PAYMENTS_API_PATH = `${SQUARE_PAYMENTS_API_PATH}/add`;
export const EDIT_SQUARE_PAYMENT_API_PATH = `${SQUARE_PAYMENTS_API_PATH}/edit?id={squarePaymentId}`;
export const REPROCESS_SQUARE_PAYMENTS_API_PATH = `${SQUARE_PAYMENTS_API_PATH}/reprocess-existing-square-payments`;
export const DELETE_SQUARE_PAYMENT_API_PATH = `${SQUARE_PAYMENTS_API_PATH}?id={squarePaymentId}`;

export const SQUARE_LOCATIONS_API_PATH = "square-location";
export const GET_ALL_SQUARE_LOCATIONS_API_PATH = `${SQUARE_LOCATIONS_API_PATH}/all`;
export const GET_SINGLE_SQUARE_LOCATION_API_PATH = `${SQUARE_LOCATIONS_API_PATH}?id={squareLocationId}`;
export const ADD_SQUARE_LOCATIONS_API_PATH = `${SQUARE_LOCATIONS_API_PATH}/add`;
export const EDIT_SQUARE_LOCATION_API_PATH = `${SQUARE_LOCATIONS_API_PATH}/edit?id={squareLocationId}`;
export const REPROCESS_SQUARE_LOCATIONS_API_PATH = `${SQUARE_LOCATIONS_API_PATH}/reprocess-existing-square-locations`;
export const DELETE_SQUARE_LOCATION_API_PATH = `${SQUARE_LOCATIONS_API_PATH}?id={squareLocationId}`;

export const VENDORS_API_PATH = "vendor";
export const GET_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const GET_ALL_DELETED_VENDORS_API_PATH = `${VENDORS_API_PATH}/all-deleted`;
export const GET_SINGLE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const ADD_VENDORS_API_PATH = `${VENDORS_API_PATH}/add`;
export const EDIT_VENDOR_API_PATH = `${VENDORS_API_PATH}/edit?id={vendorId}`;
export const RESTORE_DELETED_VENDOR_API_PATH = `${VENDORS_API_PATH}/restore?id={vendorId}`;
export const DELETE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;

export const VENDOR_DELIVERY_TIMES_API_PATH = "vendor-delivery-time";
export const GET_ALL_VENDOR_DELIVERY_TIMES_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}/all`;
export const GET_ALL_DELIVERY_TIMES_FOR_SPECIFIC_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}/all-vendor?vendor={vendorName}`;
export const GET_SINGLE_VENDOR_DELIVERY_TIME_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}?id={vendorDeliveryId}`;
export const ADD_VENDOR_DELIVERY_TIMES_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}/add`;
export const EDIT_VENDOR_DELIVERY_TIME_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}/edit?id={vendorDeliveryId}`;
export const DELETE_VENDOR_DELIVERY_TIME_API_PATH = `${VENDOR_DELIVERY_TIMES_API_PATH}?id={vendorDeliveryId}`;

export const EXTERNAL_VENDOR_INTERFACE_API_PATH = "external-vendor-interface";
export const PRICE_CHECK_VENDORS_API_PATH = `${EXTERNAL_VENDOR_INTERFACE_API_PATH}/price-check-vendors`;
export const PRICE_CHECK_PER_VENDOR_API_PATH = `${EXTERNAL_VENDOR_INTERFACE_API_PATH}/price-check-per-api-vendor?nags_part_nr={nagsPartNr}&vendor={vendorName}`;

export const AGD_INTERFACE_API_PATH = "agd-interface";
export const AGD_SHIPPING_TERMS_API_PATH = `${AGD_INTERFACE_API_PATH}/terms?auth_key={authKey}&customer_registration_key={customerRegistrationKey}`;

export const EXTERNAL_ORDERS_API_PATH = "external-part-order";
export const GET_ALL_EXTERNAL_ORDERS_API_PATH = `${EXTERNAL_ORDERS_API_PATH}/all`;
export const GET_SINGLE_EXTERNAL_ORDER_API_PATH = `${EXTERNAL_ORDERS_API_PATH}?id={externalOrderId}`;
export const ADD_EXTERNAL_ORDERS_API_PATH = `${EXTERNAL_ORDERS_API_PATH}/add`;
export const EDIT_EXTERNAL_ORDER_API_PATH = `${EXTERNAL_ORDERS_API_PATH}/edit?id={externalOrderId}`;
export const DELETE_EXTERNAL_ORDER_API_PATH = `${EXTERNAL_ORDERS_API_PATH}?id={externalOrderId}`;
export const CHANGE_DELIVERY_DRIVER_EXTERNAL_ORDER_API_PATH = `${EXTERNAL_ORDERS_API_PATH}/change-delivery-driver?id={externalOrderId}&delivery_driver_user_id={userId}`;

export const PORTAL_NOTIFICATIONS_API_PATH = "portal-notification";
export const GET_ALL_PORTAL_NOTIFICATIONS_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/all`;
export const GET_MY_PORTAL_NOTIFICATIONS_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/my-notifications`;
export const GET_COUNT_ONLY_MY_PORTAL_NOTIFICATIONS_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/my-notifications-count`;
export const GET_SINGLE_PORTAL_NOTIFICATION_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}?id={portalNotificationId}`;
export const ADD_PORTAL_NOTIFICATIONS_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/add`;
export const EDIT_PORTAL_NOTIFICATION_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/edit?id={portalNotificationId}`;
export const ACKNOWLEDGDE_PORTAL_NOTIFICATION_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}/acknowledge?id={portalNotificationId}`;
export const DELETE_PORTAL_NOTIFICATION_API_PATH = `${PORTAL_NOTIFICATIONS_API_PATH}?id={portalNotificationId}`;

export const NAGS_PART_NR_NOTES_API_PATH = "nags-part-nr-note";
export const GET_ALL_NAGS_PART_NR_NOTES_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}/all`;
export const GET_ALL_NAGS_PART_NR_NOTES_FOR_NAGS_PART_NR_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}/all-for-nags-part-nr?nags_part_nr={nagsPartNr}`;
export const GET_SINGLE_NAGS_PART_NR_NOTE_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}?id={chatQuickResponseCategoryId}`;
export const ADD_NAGS_PART_NR_NOTES_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}/add`;
export const EDIT_NAGS_PART_NR_NOTE_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}/edit?id={chatQuickResponseCategoryId}`;
export const DELETE_NAGS_PART_NR_NOTE_API_PATH = `${NAGS_PART_NR_NOTES_API_PATH}?id={chatQuickResponseCategoryId}`;

export const ACCOUNT_METRICS_API_PATH = "account-metrics";
export const COMMON_HEADER_COUNTS_API_PATH = `${ACCOUNT_METRICS_API_PATH}/common-header-counts`;
export const QUOTE_STAGE_ATTENTION_NEEDED_COUNTS_API_PATH = `${ACCOUNT_METRICS_API_PATH}/quote-callers-timer`;

export const TECHNICIAN_JOB_API_PATH = "technician-job";
export const GET_ALL_JOB_ENTRIES_API_PATH = `${TECHNICIAN_JOB_API_PATH}/all-job-entries`;
export const EDIT_TECHNICIANS_FOR_JOB_ENTRY_API_PATH = `${TECHNICIAN_JOB_API_PATH}/update-technician-jobs-for-job`;

export const AVAILABLE_EMPLOYEES_API_PATH = "available-employees";
export const GET_ALL_AVAILABLE_TECHNICIANS_API_PATH = `${AVAILABLE_EMPLOYEES_API_PATH}/all-technicians`;
export const GET_ALL_AVAILABLE_CSR_AGENTS_API_PATH = `${AVAILABLE_EMPLOYEES_API_PATH}/all-csr-agents`;

export const HB_QB_INTEGRATION_API_PATH = "hb-qb-intergration-interface";
export const REFRESH_HB_TIME_CARDS_API_PATH = `${HB_QB_INTEGRATION_API_PATH}/refresh-time-cards`;

export const FOLLOW_UP_PHONE_NUMBER_API_PATH = "phone-number-follow-up";
export const MARK_PHONE_NUMBER_AS_FOLLOW_UP = `${FOLLOW_UP_PHONE_NUMBER_API_PATH}/mark-phone-number-as-followed-up?phone_number={phoneNumber}`;
export const ADD_PHONE_NUMBER_TO_FOLLOW_UP_API_PATH = `${FOLLOW_UP_PHONE_NUMBER_API_PATH}/add-phone-number-follow-up`;

export const EGR_LEADS_API_PATH = "egr-lead";
export const GET_ALL_EGR_LEADS_API_PATH = `${EGR_LEADS_API_PATH}/all`;
export const GET_SINGLE_EGR_LEAD_API_PATH = `${EGR_LEADS_API_PATH}?id={egrLeadId}`;

export const CUSTOMER_FACING_INTERFACE_API_PATH = "customer-facing-interface";
export const GET_ALL_ACTIVE_JOBS_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/active-jobs`;
export const GET_SINGLE_JOB_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/job?intake_form_id={intakeFormId}`;
export const GET_SINGLE_JOB_LIABILITY_WAIVER_INFO_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/job-liability-waiver-info?intake_form_id={intakeFormId}`;
export const UPDATE_CUSTOMER_INFO_SHORT_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/customer-confirm-details?intake_form_id={intakeFormId}`;
export const GET_WAIVER_FULL_INFO_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/liability-waiver-all-data?waiver_id={waiverId}`;
export const GET_ANONYMOUS_ACCESS_WAIVER_FULL_INFO_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/anonymous-liability-waiver-all-data-fetch?waiver_id={waiverId}&sign_key={signKey}`;
export const SEND_SIGNED_DOCUMENTS_TO_CUSTOMER_API_PATH = `${CUSTOMER_FACING_INTERFACE_API_PATH}/send-signed-documents-to-customer?intake_form_id={intakeFormId}`;

export const CUSTOMER_WAIVERS_API_PATH = "intake-form-liability-waiver";
export const UPLOAD_SIGNED_WAIVER_FILE = `${CUSTOMER_WAIVERS_API_PATH}/upload-signed-waiver?waiver_id={waiverId}`;
export const UPLOAD_SIGNED_WAIVER_FILE_SIGN_KEY_API_PATH = `${CUSTOMER_WAIVERS_API_PATH}/upload-signed-waiver-sign-key?waiver_id={waiverId}&sign_key={signKey}`;
export const SEND_WAIVER_TO_CUSTOMER_TO_SIGN = `${CUSTOMER_WAIVERS_API_PATH}/send-waiver-to-customer-for-signing?waiver_id={waiverId}`;
