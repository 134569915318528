import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { sharedStyle } from "../sharedStyle";
import { parseOutRequiredData } from "../helpers";

const ReleaseOfLiabilityWaiver = ({
    signature,
    setRenderingBlob,
    data = {},
}) => {

  let { customerFullName, services, totalSaleAmount } = parseOutRequiredData(data);

  return (
    <Document 
      onRender={(render) => {
        setRenderingBlob(render)
      }}
    >
      <Page style={sharedStyle.body}>
        <Image
          style={sharedStyle.logo}
          src={"https://i.imgur.com/MQGUWRX.png"}
          fixed
        />
        <Text style={{ ...sharedStyle.header, marginBottom: 4 }}>
          Atlas Auto Glass Holdings, LLC
        </Text>
        <Text style={sharedStyle.header}>
          1712 W. Dallas St., Houston, Texas 77019
        </Text>
        <Text style={sharedStyle.title}>
          WAIVER AND RELEASE OF LIABILITY
        </Text>

        <Text style={sharedStyle.textSmallBottomMargin}>
          By Clicking accept below, I {customerFullName} (customer) agree that I have read and understand
          the following document and I authorize Atlas Auto Glass Holdings, LLC, (Atlas), to provide
          the following service(s) to my vehicle.
        </Text>
        <Text style={sharedStyle.listServices}>
          {services.join(", ")}
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Subject to completion of the work, I agree to pay the amount of ${totalSaleAmount}. If I indicated 
          I am using my insurance policy to pay for the listed services, I agree to pay my deductible to Atlas, 
          and assign to Atlas, any claim that I have under my insurance policy to recover and authorize my 
          insurance company to pay Atlas the balance due. If said amount is not paid in full by my insurance 
          company, I agree to pay the unpaid balance.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas Auto Glass will not recommend or endorse service outside of warranty coverage; it is at the customer's 
          sole discretion should they choose to have said service rendered.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Customer discloses and represents to Atlas Auto Glass that: (a) Customer is the legal owner or lessee of 
          the Vehicle and (b) Customer has the legal right to authorize Atlas Auto Glass to undertake and perform 
          the required repairs and/or replacements of the vehicle.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas is not responsible for loss or damage to cars or articles left in cars in case of fire, theft or 
          any other cause beyond our control. I hereby authorize the above repair work to be done along with the 
          necessary material, and hereby grant Atlas and contractors permission to operate the car, truck, or vehicle 
          herein described on streets, highways, or elsewhere for the purpose of testing and/or inspection. An express 
          mechanic's lien is hereby acknowledged on above car, truck or vehicle to secure the amount of repairs thereto.
        </Text>
        <Text style={sharedStyle.text}>
          Signature:
        </Text>
        <Image
          style={sharedStyle.signatureImage}
          src={signature}
        />
        <Text style={sharedStyle.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default ReleaseOfLiabilityWaiver;
