import React from "react";
import {
  HomeOutlined,
  ShopOutlined,
  ToolOutlined,
  TeamOutlined,
  DollarOutlined,
  SettingOutlined,
  FileOutlined,
  AuditOutlined,
  ContainerOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CodepenOutlined,
  MessageOutlined,
  FormOutlined,
  EditOutlined,
  BankOutlined,
  ReconciliationOutlined,
  BlockOutlined,
  NotificationOutlined,
  RocketOutlined,
  ImportOutlined,
} from "@ant-design/icons";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import NotFoundPage from "./pages/404Page";
import SignAWaiverPage from "./pages/signWaiverCustomerDevice";

const CheckInPage = React.lazy(() =>
  import("./pages/checkInPage")
)
const ConfirmInfoPage = React.lazy(() =>
  import("./pages/confirmInfoPage")
)
const LiabilityWaiversPage = React.lazy(() =>
  import("./pages/liabilityWaiversPage")
)
const SignLiabilityWaiverPage = React.lazy(() =>
  import("./pages/signLiabilityWaiverPage")
)

export const CHECK_IN_PAGE_ROUTE = "/check-in";

export const CONFIRM_INFO_PAGE_ROUTE = "/confirm-info/:intakeFormId";

export const LIABILITY_WAIVERS_PAGE_ROUTE = "/liability-waivers/:intakeFormId";

export const SIGN_LIABILITY_WAIVER_PAGE_ROUTE = "/sign-liability-waiver/:liabilityWaiverId/:intakeFormId";

export const INDEX_ROUTE = "/";

export const CUSTOMER_SIGN_A_WAIVER_ROUTE = "/sign-a-waiver/:liabilityWaiverId/:signKey";
// ROUTES
export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    exact: true,
    name: "sign-a-waiver",
    path: CUSTOMER_SIGN_A_WAIVER_ROUTE,
    component: SignAWaiverPage,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    exact: true,
    name: "sign-a-waiver",
    path: CUSTOMER_SIGN_A_WAIVER_ROUTE,
    component: SignAWaiverPage,
  },
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "check-in",
        path: CHECK_IN_PAGE_ROUTE,
        component: CheckInPage,
      },
      {
        exact: true,
        name: "confirm-info",
        path: CONFIRM_INFO_PAGE_ROUTE,
        component: ConfirmInfoPage,
      },
      {
        exact: true,
        name: "liability-waiver",
        path: LIABILITY_WAIVERS_PAGE_ROUTE,
        component: LiabilityWaiversPage,
      },
      {
        exact: true,
        name: "sign-liability-waiver",
        path: SIGN_LIABILITY_WAIVER_PAGE_ROUTE,
        component: SignLiabilityWaiverPage,
      }
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Check In",
    route: CHECK_IN_PAGE_ROUTE,
    icon: <HomeOutlined />,
  },
];
