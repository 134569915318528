import dayjs from "dayjs";
import _ from "lodash";

export const areArraysEqual = (array1, array2) => {
  return _.isEmpty(_.xor(array1, array2))
}

/* eslint-disable no-useless-escape */
export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== false &&
      obj[key] !== undefined
    )
      return false;
  }
  return true;
};

export const allowOnlyIntegers = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
};

export const roundOff = (value) => {
  return Math.round(value * 100) / 100;
};

export const random = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const range = (start, end) =>
  Array.from({ length: end + 1 - start }, (v, k) => k + start);

export const sort = (data, sortKey) => {
  return data.sort((a, b) => {
    return (
      (a[sortKey] === null) - (b[sortKey] === null) ||
      +(a[sortKey] > b[sortKey]) ||
      -(a[sortKey] < b[sortKey])
    );
  });
};

export const getAttributeFiltersQueryString = (values) => {
  const filtersArray = [];
  console.log(values);
  Object.keys(values).forEach((key) => {
    let valueString = "";
    if (Array.isArray(values[key])) {
      valueString = values[key].join("|");
    } else {
      valueString = values[key].toString().split("|").join("|");
    }
    filtersArray.push(`${key}=${valueString}`);
  });
  return filtersArray.join("&");
};

export const getTableSortValue = (sorter, colKey) => {
  return sorter === colKey
    ? "ascend"
    : sorter === `-${colKey}`
    ? "descend"
    : false;
};

export const setFilterObjectBasedOnFilteringString = (
    filteringAttributeString, 
    form, 
    setObjectFilters
  ) => {
  if (filteringAttributeString) {
    const values = {};
    const initialValues = {};
    const formValues = form.getFieldValue();
    console.log(formValues);
    filteringAttributeString.split("&").forEach((value) => {
      const splits = value.split("=");
      const valueSplits = splits[1].split("|").filter((x) => x);
      values[splits[0]] = valueSplits.length
        ? valueSplits.map((i) => isNaN(i) ? i : parseInt(i))
        : [];
      if (splits[0] === "formatted_phone_number"){
        values[splits[0]] = values[splits[0]][0]
      }
    });
    console.log("values", values);
    if (isEmpty(formValues)) {
      form.setFieldsValue(values);
    } else {
      Object.keys(formValues).forEach((key) => {
        initialValues[key] = values[key] || [];
      });
      //console.log(initialValues);
      form.setFieldsValue(initialValues);
    }

    setObjectFilters(values);
  }
}