export const sharedStyle = {
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginTop: 20,
    fontSize: 22,
    fontFamily: 'Times-Bold',
    textAlign: 'center',
  },
  paragraphHeader: {
    margin: 12,
    marginBottom: -10,
    fontSize: 14,
    fontFamily: 'Times-Bold',
    textAlign: 'justify',
    fontWeight: 'bold',
  },
  adasDeclineHeader: {
    margin: 12,
    marginBottom: 0,
    fontSize: 14,
    fontFamily: 'Times-Bold',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  listServices: {
    margin: 12,
    marginBottom: 4,
    fontSize: 14,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  textSmallBottomMargin: {
    margin: 12,
    marginBottom: 3,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  bulletPoint: {
    marginLeft: 40,
    marginBottom: 2,
    flexDirection: "row",
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  signatureImage: {
    marginVertical: 5,
    marginHorizontal: 20,
    height: 50,
    width: 100
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 20,
    height: "100",
    width: "100"
  },
  logo: {
    marginVertical: 5,
    marginHorizontal: 20,
    height: "80",
    width: "80",
    maxHeight: "100",
    alignSelf: "start",
    position: 'absolute',
    top: 10,
    left: 35,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'black',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
};