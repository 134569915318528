import { Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { parseOutRequiredData } from '../helpers';
import { sharedStyle } from '../sharedStyle';

const ChipRepairLiabilityWaiver = ({
  signature,
  setRenderingBlob,
  data = {},
}) => {
  let { customerFullName, services, totalSaleAmount } = parseOutRequiredData(data);

  return (
    <Document
      onRender={(render) => {
        setRenderingBlob(render)
      }}
    >
      <Page style={sharedStyle.body}>
        <Image
          style={sharedStyle.logo}
          src={"https://i.imgur.com/MQGUWRX.png"}
        />
        <Text style={{ ...sharedStyle.header, marginBottom: 4 }}>
          Atlas Auto Glass Holdings, LLC
        </Text>
        <Text style={sharedStyle.header}>
          1712 W. Dallas St., Houston, Texas 77019
        </Text>
        <Text style={sharedStyle.title}>
          WAIVER AND RELEASE OF LIABILITY
        </Text>

        <Text style={sharedStyle.textSmallBottomMargin}>
          By Clicking accept below, I {customerFullName} (customer) agree that I have read and understand
          the following document and I authorize Atlas Auto Glass Holdings, LLC, (Atlas), to provide
          the following service(s) to my vehicle.
        </Text>
        <Text style={sharedStyle.listServices}>
          {services.join(", ")}
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Subject to completion of the work, I agree to pay the amount of ${totalSaleAmount}. If I indicated
          I am using my insurance policy to pay for the listed services, I agree to pay my deductible to Atlas,
          and assign to Atlas, any claim that I have under my insurance policy to recover and authorize my
          insurance company to pay Atlas the balance due. If said amount is not paid in full by my insurance
          company, I agree to pay the unpaid balance.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas Auto Glass will not recommend or endorse service outside of warranty coverage; it is at the customer's
          sole discretion should they choose to have said service rendered.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Customer discloses and represents to Atlas Auto Glass that: (a) Customer is the legal owner or lessee of
          the Vehicle and (b) Customer has the legal right to authorize Atlas Auto Glass to undertake and perform
          the required repairs and/or replacements of the vehicle.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas is not responsible for loss or damage to cars or articles left in cars in case of fire, theft or
          any other cause beyond our control. I hereby authorize the above repair work to be done along with the
          necessary material, and hereby grant Atlas and contractors permission to operate the car, truck, or vehicle
          herein described on streets, highways, or elsewhere for the purpose of testing and/or inspection. An express
          mechanic's lien is hereby acknowledged on above car, truck or vehicle to secure the amount of repairs thereto.
        </Text>
        <Text style={sharedStyle.paragraphHeader}>
          Chip Repair
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          The purpose of the repair process is to restore the structural integrity of the damaged windshield. 
          While the crack may or may not improve in appearance after the repair process is completed, the repair 
          is not meant to be cosmetic. Once completed, the windshield repair will still be visible, even after 
          being successfully repaired. The best results are obtained when the damage is recent, the point of impact
          is small, the cracks around the damaged area are small, and there is no moisture or other foreign matters
          in the damaged area.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          During the repair process, there is a possibility of the rock chip/crack spreading beyond the point of 
          repair. Pressure is being put on the broken glass by injecting resin into the crack. There is no way to 
          determine whether or not this will happen prior to beginning the repair process.
          If the crack does spread beyond the point of repair, we are not responsible for such damage. However, 
          we will credit the cost of the repair toward a replacement. If your insurance company paid for the repair, 
          the insurance company will receive the credit. This applies has long as you own or lease the vehicle on 
          which the repair was made and is not transferrable.
          I have read and understand these facts about the windshield repair process.
        </Text>
        
        <Text style={sharedStyle.text}>
          Signature:
        </Text>
        <Image
          style={sharedStyle.signatureImage}
          src={signature}
        />
        <Text style={sharedStyle.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};
export default ChipRepairLiabilityWaiver;
