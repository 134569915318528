import { formatCustomerDisplayValue } from "../../../helpers";

export const parseOutRequiredData = (data) => {
  let customerFullName = "";
  let totalSaleAmount = "";
  let services = [];
  let adasDeclined = false;
  let hasGlassRepairJobs = false;
  let hasChipRepairJobs = false;
  if (data?.intake_form !== undefined) {
    if ((data?.intake_form?.body_and_paint_jobs || []).length > 0)
      services.push("Body and Paint");
    if ((data?.intake_form?.chip_repair_jobs || []).length > 0){
      services.push("Chip Repair");
      hasChipRepairJobs = true;
    }
    if ((data?.intake_form?.glass_replacement_jobs || []).length > 0){
        services.push("Glass Replacement");
        hasGlassRepairJobs = true;
        for (const glassJob of data?.intake_form?.glass_replacement_jobs) {
            if (glassJob.adas_declined_status === "Yes") {
                adasDeclined = true;
                break;
            }
        }
    }
    if ((data?.intake_form?.tint_only_jobs || []).length > 0)
      services.push("Tint");
    if ((data?.intake_form?.vehicle_detail_jobs || []).length > 0)
      services.push("Vehicle Detail");

    customerFullName = formatCustomerDisplayValue(data?.intake_form?.customer);
    totalSaleAmount = data?.intake_form?.total_price;
  }
  return { customerFullName, services, totalSaleAmount, adasDeclined, hasGlassRepairJobs, hasChipRepairJobs };
}