import { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Checkbox, Card, message, Spin, Result } from "antd";

import { getAnonymousAccessWaiverFullInfo, loginUser } from "../../actions";

import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import SignLiabilityWaiverComponent from "../../components/signLiabilityWaiverComponent";

const SignAWaiverPage = () => {
  const [liabilityWaiverData, setLiabilityWaiverData] = useState({});
  const [isLiabilityWaiverDataLoading, setIsLiabilityWaiverDataLoading] = useState(true);
  const [intakeFormId, setIntakeFormId] = useState(null)
  const [isSignLiabilityWaiverComponentVisible, setIsSignLiabilityWaiverComponentVisible] = useState(true);

  const params = useParams();
  const { liabilityWaiverId, signKey } = params;

  const loadDataForWaiver = () => {
    setIsLiabilityWaiverDataLoading(true);
    getAnonymousAccessWaiverFullInfo(liabilityWaiverId, signKey).then((response) => {
      setIsLiabilityWaiverDataLoading(false);
      const { data } = response;
      setLiabilityWaiverData({
        ...data,
        liabilityWaiverId: liabilityWaiverId
      })
      setIntakeFormId(data.intake_form.id);
    }).catch((err) => {
      setIsLiabilityWaiverDataLoading(false);
      message.error(err?.response?.data?.detail);
    })
  }

  useEffect(() => {
    console.log({ liabilityWaiverId, signKey });
    loadDataForWaiver();
  }, [signKey]);

  return (
    <div>
      <Spin spinning={isLiabilityWaiverDataLoading} />
      <Card bordered>
        <If condition={!isSignLiabilityWaiverComponentVisible}>
        <Result
          status="success"
          title="Waiver successfuly signed!"
          subTitle={
            <>
              Click 
              <Button type="link" onClick={() => {window.open(liabilityWaiverData.signed_upload_result.signed_doc_url, "_blank");}}>here</Button> to download your copy of signed waiver.
            </>
          }
        />
        </If>
        <If condition={isSignLiabilityWaiverComponentVisible}>
          <p className="text-2xl semi-bold mb-16 mt-16">Sign A Waiver</p>
          <SignLiabilityWaiverComponent
            liabilityWaiverData={liabilityWaiverData}
            setLiabilityWaiverData={setLiabilityWaiverData}
            isLiabilityWaiverDataLoading={isLiabilityWaiverDataLoading}
            setIsLiabilityWaiverDataLoading={setIsLiabilityWaiverDataLoading}
            liabilityWaiverId={liabilityWaiverId}
            intakeFormId={intakeFormId}
            signKey={signKey}
            onSuccessfulSigning={() => {
              setIsSignLiabilityWaiverComponentVisible(false);
            }}
          />
        </If>
        
      </Card>
    </div>
  );
};
export default SignAWaiverPage;