import { useHistory } from "react-router";
import { Button, message, Carousel, Space, Spin, List } from 'antd';
import { useEffect, useState } from "react";
import { uploadSignedWaiverFile, uploadSignedWaiverFileWithSignKey } from "../../actions";
import { pdf } from '@react-pdf/renderer';
import SignatureCanvas from 'react-signature-canvas'

import { convertPdfBlobToImages } from "../../helpers";
import GeneralLiabilityWaiver from "./waiverComponents/generalLiabilityWaiver";
import ReleaseOfLiabilityWaiver from "./waiverComponents/releaseOfLiabilityWaiver";
import TextArea from "antd/lib/input/TextArea";
import GlassRepairLiabilityWaiver from "./waiverComponents/glassRepairLiabilityWaiver";
import ChipRepairLiabilityWaiver from "./waiverComponents/chipRepairLiabilityWaiver";
import WindshieldRepairLiabilityWaiver from "./waiverComponents/windshieldRepairLiabilityWaiver";
import SingleDocumentLiabilityWaiver from "./waiverComponents/singleDocumentLiabilityWaiver";

const SignLiabilityWaiverComponent = ({
  liabilityWaiverData, 
  setLiabilityWaiverData,
  isLiabilityWaiverDataLoading, 
  setIsLiabilityWaiverDataLoading,
  liabilityWaiverId, 
  intakeFormId,
  signKey=null,
  onSuccessfulSigning=undefined,
}) => {
  const history = useHistory();

  const [signature, setSignature] = useState("data:image/jpeg;base64,/9j/2wCEAAg");
  const [isSigned, setIsSigned] = useState(false);
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [PDFImgSources, setPDFImgSources] = useState([]);
  const [PDFBlob, setPDFBlob] = useState(null);

  const [signatureDimensions, setSignatureDimensions] = useState({
    width: 0, height: 0
  });

  const [renderingBlob, setRenderingBlob] = useState(null);

  let sigPad;

  const listOfPDFImagesStyle = {
    width: "100%",
    maxWidth: "600px"
  };

  const setPDFImageSourcesFromBlob = async (blob) => {
    const images = await convertPdfBlobToImages(blob);
    setPDFImgSources(images);
  };

  const readPDFBlobAndSetImages = () => {
    pdf(LiabilityElement).toBlob().then(async (blobValue) => {
      setPDFImageSourcesFromBlob(blobValue);
    });
  }

  useEffect(() => {
    // check if liability_waiver is loaded
    if (liabilityWaiverData.liability_waiver === undefined) return;

    readPDFBlobAndSetImages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liabilityWaiverData])


  useEffect(() => {
    if (renderingBlob === null || renderingBlob === undefined) return;
    setPDFBlob(renderingBlob.blob);
  }, [renderingBlob])

  useEffect(() => {
    if (!isSigned) return;
    readPDFBlobAndSetImages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned])

  useEffect(() => {
    if (!isReset) return;
    setIsReset(false);
    readPDFBlobAndSetImages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset])

  useEffect(() => {
    if (PDFBlob === null || PDFBlob === undefined) return;
    setPDFImageSourcesFromBlob(PDFBlob);
  }, [PDFBlob])

  useEffect(() => {
    
  }, []);

  const LiabilityElement = (
    <>
      <If condition={liabilityWaiverData?.liability_waiver?.type === "GlassRepairLiabilityWaiver"}>
        <GlassRepairLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
          />
      </If>
      <If condition={liabilityWaiverData?.liability_waiver?.type === "ChipRepairLiabilityWaiver"}>
        <ChipRepairLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
          />
      </If>
      <If condition={liabilityWaiverData?.liability_waiver?.type === "ReleaseOfLiabilityWaiver"}>
        <ReleaseOfLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
          />
      </If>
      <If condition={liabilityWaiverData?.liability_waiver?.type === "WindshieldRepairLiabilityWaiver"}>
        <WindshieldRepairLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
          />
      </If>
      <If condition={liabilityWaiverData?.liability_waiver?.type === "SingleDocumentLiabilityWaiver"}>
        <SingleDocumentLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
        />
      </If>
      <If condition={["GeneralLiabilityWaiver"].includes(liabilityWaiverData?.liability_waiver?.type)}>
        <GeneralLiabilityWaiver
          signature={signature}
          data={liabilityWaiverData}
          setRenderingBlob={setRenderingBlob}
        />
      </If>
    </>
  );

  return (
    <div>
      <Spin spinning={isUploadActive || isLiabilityWaiverDataLoading} />
      <If condition={!isUploadActive}>
        <div 
          style={{ 
            display: "block", 
            width: "100%", 
            maxWidth: 600,
            maxHeight: "700px", 
            overflow: "scroll", 
            borderRadius: "4px", 
            borderStyle: "solid", 
            borderWidth: "1px"
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={PDFImgSources}
            renderItem={(item) => (
              <List.Item>
                <img
                  src={item}
                  alt="pdf"
                  style={listOfPDFImagesStyle} />
              </List.Item>
            )}
          />
        </div>
        <If condition={liabilityWaiverData?.liability_waiver?.type === "ReleaseOfLiabilityWaiver"}>
          <b style={{ display: "block" }}>Enter Release of Liability Waiver Text:</b>
          <TextArea
            placeholder="Enter Release of Liability Waiver Text"
            onChange={(e) => {
              setLiabilityWaiverData({
                ...liabilityWaiverData,
                employee_note: e.target.value
              })
            }}
          />
        </If>
        <b style={{ display: "block" }}>Sign Here:</b>
        <Space>
          <div style={{ width: "500px", borderStyle: "solid" }} className="canvasWrapper">
            <SignatureCanvas
              clearOnResize={false}
              ref={sigPadRef => { sigPad = sigPadRef; }}
              penColor='blue'
              canvasProps={{ width: 500, height: 150, className: 'sigCanvas', style: {
                cursor: isSigned ? "not-allowed" : "default",
                pointerEvents: isSigned ? "none" : "auto",
                backgroundColor: isSigned ? "lightgray" : "transparent",
                opacity: isSigned ? 0.4 : 1,
              } }}
            />
          </div>
          <Button
            type="primary"
            onClick={() => {
              if (isSigned) {
                setIsUploadActive(true);
                const blob = pdf(LiabilityElement).toBlob().then(async (blobValue) => {
                  const formData = new FormData();
                  const file = new File([blobValue], "file.pdf");
                  formData.append("file", file);
                  // if we are calling this from Sales Portal employee device, we can sign the file without sign key
                  // however if the user is signing from his device, he will need a key to properly sign the signature
                  // above described is a security measure to prevent unauthorized users from signing the signature 
                  let funcToCall = uploadSignedWaiverFile;
                  if (signKey !== null && signKey !== undefined){
                    funcToCall = uploadSignedWaiverFileWithSignKey
                  }
                  funcToCall(liabilityWaiverId, formData, signKey).then((response) => {
                    setIsUploadActive(false);
                    const { data } = response;
                    message.success("Document successfully signed!");
                    // go back to list of all liability waivers for an intake form if we are calling this component
                    // from the Sales Portal employee device
                    // User devices should only get a message that the liability waiver is signed and download it?
                    if (signKey === null || signKey === undefined) {
                      history.push("/liability-waivers/" + intakeFormId)
                    }
                    setLiabilityWaiverData((curr) => {
                      return {
                        ...curr,
                        signed_upload_result: data
                      }
                    })
                    
                    if (onSuccessfulSigning !== undefined) {
                      onSuccessfulSigning()
                    }
                  }).catch((err) => {
                    setIsUploadActive(false);
                    message.error(err?.response?.data?.detail || "Unknown error, please contact the developer!");
                  });
                });
              }
              const trimmedCanvas = sigPad.getCanvas();
              setSignatureDimensions({
                width: trimmedCanvas.width,
                height: trimmedCanvas.height
              });
              const signatureBase64 = trimmedCanvas.toDataURL('image/png');
              setSignature(signatureBase64);
              setIsSigned(true);              
            }}> {isSigned ? "Confirm Signature" : "Sign"} </Button>
          <Button onClick={() => {
            sigPad.clear()
            const a = sigPad.getTrimmedCanvas().toDataURL('image/png');
            setSignature(a);
            setIsSigned(false);
            setIsReset(true);
          }}> Reset </Button>
        </Space>
      </If>
    </div>
  );
};

export default SignLiabilityWaiverComponent;
