import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { parseOutRequiredData } from '../helpers';
import { sharedStyle } from '../sharedStyle';
import moment from 'moment';

const GlassRepairLiabilityWaiver = ({
  signature,
  setRenderingBlob,
  data = {},
}) => {
  
  let { customerFullName, services, totalSaleAmount, adasDeclined } = parseOutRequiredData(data);

  let currentDateTime = moment().format('MM/DD/YYYY');

  return (
    <Document
      onRender={(render) => {
        setRenderingBlob(render)
      }}
    >
      <Page style={sharedStyle.body}>
        <Image
          style={sharedStyle.logo}
          src={"https://i.imgur.com/MQGUWRX.png"}
        />
        <Text style={{ ...sharedStyle.header, marginBottom: 4 }}>
          Atlas Auto Glass Holdings, LLC
        </Text>
        <Text style={sharedStyle.header}>
          1712 W. Dallas St., Houston, Texas 77019
        </Text>
        <Text style={sharedStyle.title}>
          WAIVER AND RELEASE OF LIABILITY
        </Text>

        <Text style={sharedStyle.textSmallBottomMargin}>
          By Clicking accept below, I {customerFullName} (customer) agree that I have read and understand
          the following document and I authorize Atlas Auto Glass Holdings, LLC, (Atlas), to provide
          the following service(s) to my vehicle.
        </Text>
        <Text style={sharedStyle.listServices}>
          {services.join(", ")}
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Subject to completion of the work, I agree to pay the amount of ${totalSaleAmount}. If I indicated 
          I am using my insurance policy to pay for the listed services, I agree to pay my deductible to Atlas, 
          and assign to Atlas, any claim that I have under my insurance policy to recover and authorize my 
          insurance company to pay Atlas the balance due. If said amount is not paid in full by my insurance 
          company, I agree to pay the unpaid balance.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas Auto Glass will not recommend or endorse service outside of warranty coverage; it is at the customer's 
          sole discretion should they choose to have said service rendered.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Customer discloses and represents to Atlas Auto Glass that: (a) Customer is the legal owner or lessee of 
          the Vehicle and (b) Customer has the legal right to authorize Atlas Auto Glass to undertake and perform 
          the required repairs and/or replacements of the vehicle.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas is not responsible for loss or damage to cars or articles left in cars in case of fire, theft or 
          any other cause beyond our control. I hereby authorize the above repair work to be done along with the 
          necessary material, and hereby grant Atlas and contractors permission to operate the car, truck, or vehicle 
          herein described on streets, highways, or elsewhere for the purpose of testing and/or inspection. An express 
          mechanic's lien is hereby acknowledged on above car, truck or vehicle to secure the amount of repairs thereto.
        </Text>

        <Text style={sharedStyle.paragraphHeader}>
          Glass Replacement
        </Text>
        <Text style={sharedStyle.paragraphHeader}>
          LIMITED LIFETIME WARRANTY COVERAGE:
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Atlas offers an industry leading lifetime warranty on glass and installation in the event of 
          manufacturer defects in the glass or faulty installation. This Limited Lifetime Warranty is specific 
          to work or repairs performed by Atlas. All claims and/or repairs associated with this Limited Warranty 
          must be performed at Atlas. Atlas will not pay for or reimburse you for warranty claims under this 
          Limited Warranty if the replacement of parts and/or labor is performed by any other facility or person. 
          In the event of a Covered Part, subject to the terms, conditions, and limitations herein, Atlas will 
          replace the Covered Part(s), including labor; with a new same or part of like quality whenever possible, 
          however, in the event a new OEM part is not available, Atlas reserves the right to: (i) repair the 
          existing defective or broken part, (ii) replace it with the same part or one of like kind and quality. 
          Parts replaced under this Limited Warranty do not include an Atlas Limited Lifetime warranty, unless 
          otherwise stated in writing by Atlas. This Limited Warranty becomes null and void if the warranted part
          is modified, or affected by any other person(s) or facility other than Atlas. A Failure or Mechanical
          Breakdown means the failure of a Covered Part due to a manufacturers defect or faulty workmanship, but
          does not include cosmetic failures or damage. A Covered Part has failed when it can no longer perform 
          the function for which it was designed. 
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          Any prior and existing body damage, rust, or related damage affecting the placement of the glass to be 
          replaced (affectation to be determined by our technician at the time of service) voids all warranties 
          on associated glass replacement. Atlas Auto Glass will use reasonable efforts to advise you of the existence 
          of such damage or rust prior to service but cannot guarantee said damage will be discovered prior to service. 
          In these cases, Atlas Auto Glass is not responsible for any associated damage.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          In addition, prior damage not involving defective workmanship or materials is expressly excluded from 
          coverage under this warranty. Atlas Auto Glass is not liable for incidental or consequential damages. 
        </Text>

        <If condition={adasDeclined}>
          <Text style={sharedStyle.adasDeclineHeader}>
            ADAS WAIVER OF RESPONSIBILITY
          </Text>
          <Text style={sharedStyle.textSmallBottomMargin}>
          By clicking accept, I agree on {currentDateTime} that I have been informed by Atlas, that my vehicle has 
          an Advanced Driver Assistance System (ADAS) that will require a calibration to work properly after 
          the windshield has been replaced. This system may or may not include one or more of the following systems:
        </Text>
        {/* Bullet point list */}
        <View style={{flexDirection: "column", width: 400}}>
          {["Adaptive Cruise Control", "Lane Departure Warning", "Rain Sensor", "Forward Collision Warning", "Traffic Sign Recognition"].map(x => {
            return (
              <View style={sharedStyle.bulletPoint}>
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text>{x}</Text>
              </View>
            )
          })}
        </View>
        <Text style={sharedStyle.textSmallBottomMargin}>
          These advanced driver assistance systems protect drivers through a range of capabilities, creating a 
          circular safety net around the vehicle to help reduce the risk of collisions. The forward-facing 
          front cameras enable advanced safety features to aid and warn drivers on the road.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          The ADAS camera systems are designed to work together to enhance vehicle safety by alerting the driver
          to potential problems and avoiding collisions - they can aid, warn and assist. Some examples include 
          automatic emergency braking, forward collision warning, lane assist, lane departure warning, 
          pedestrian detection, collision avoidance, and more. If so equipped, the camera's proper operation 
          is a critical component of your vehicle's advanced safety system, and thus, need to be recalibrated 
          after the installation of the new windshield. 
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          The Atlas Auto Glass team has offered and highly recommended that that this calibration be performed 
          and I have elected to not have Atlas Auto Glass perform this calibration and I release Atlas Auto 
          Glass of all liability as I know that the ADAS may not function properly after the new windshield 
          has been installed.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          By signing below, I acknowledge that I hereby decline recalibration of the ADAS on my vehicle offered by 
          Atlas and thereby acknowledge sole responsibility and expense associated with malfunction of ADAS sensors. 
          I fully accept the risks associated with improper function of ADAS sensors. These risks include, but are 
          not limited to, injury, physical harm to myself and others, and damage to my vehicle and others'. I agree 
          to fully release and discharge Atlas from all liability in connection to my declining of recalibrations.
        </Text>
        <Text style={sharedStyle.textSmallBottomMargin}>
          By clicking accept, I hereby acknowledge that I have read and fully understand the terms of this Waiver 
          and Release of Liability and that I have signed this Waiver and Release of Liability freely and voluntarily 
          without any inducement, assurance, guarantee or any oral representations being made.
        </Text>
        </If>

        <Text style={sharedStyle.text}>
          Signature:
        </Text>
        <Image
          style={sharedStyle.signatureImage}
          src={signature}
        />
        <Text style={sharedStyle.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default GlassRepairLiabilityWaiver;
